import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    messageDetail: 0, // 用于用户存储通知详情的id

  },
  getters: {
  },
  mutations: {
     // 接收页面刷新的时候获取路径参数进行放入vuex管理
     messBtn(state, id) {
      state.messageDetail = id
      console.log('获取到对应id立马触发',  state.messageDetail )
    }
  },
  actions: {
  },
  modules: {
  }
})
