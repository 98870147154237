import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Router from 'vue-router'

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import("../views/home/index.vue")
  },
  // 登录注册
  {
    path: '/login',
    name: 'login',

    component: () => import("../views/login/login.vue")
  },
  // 登录注册
  {
    path: '/forget',
    name: 'forget',

    component: () => import("../views/forget/forget.vue")
  },
  // 切换省份
  {
    path: '/city',
    name: 'city',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/switch-city/switch-city.vue")
  },

  // 商品详情
  {
    path: '/commodityDetails',
    name: 'commodityDetails',
    component: () => import("../views/details/commodity-details.vue")
  },
  // 积分商品详情
  {
    path: '/commodityIntergal',
    name: 'commodityIntergal',
    component: () => import("../views/details/commodity-intergal.vue")
  },
  // 搜索界面
  {
    path: '/search',
    name: 'search',
    component: () => import("../views/search/search.vue")
  },
  // vip界面
  {
    path: '/vip',
    name: 'vip',
    component: () => import("../views/vip/vip.vue")
  },
  // 个人中心界面
  {
    path: '/personal',
    name: 'personal',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/personal-center/personal-center.vue")

  },


  //限时秒杀 
  {
    path: '/seckillDiscount',
    name: 'seckillDiscount',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/seckill-discount/seckill-discount.vue")
  },
  // 超值折扣
  {
    path: '/seckillZhekou',
    name: 'seckillZhekou',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/seckill-discount/seckill-zhekou.vue")
  },
  //底部帮助中心
  {
    path: '/helpBody',
    name: 'helpBody',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/helpbuttom/helpbody.vue")
  },
  //类别详情
  {
    path: '/classSite',
    name: 'classSite',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/classify/classift-site.vue")
  },
  //商品二级类别种类详情
  {
    path: '/goodsTwo',
    name: 'goodsTwo',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/classify/goods-two.vue")
  },
  //商品二级类别种类详情
  {
    path: '/goodsTree',
    name: 'goodsTree',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../views/classify/goods-tree.vue")
  },

  // 购物车界面
  {
    path: '/shoppingTrolley',
    name: 'shoppingTrolley',
    component: () => import("../views/shopping-trolley/shopping-trolley.vue")
  },
  // 点击加入购物车成功后自动进入成功加入购物车
  {
    path: '/ShoppingCartSuccessful',
    name: 'ShoppingCartSuccessful',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/Shopping-cart-successful/shopping-cart-successful.vue")
  },
  // 订单结算界面
  {
    path: '/submitOrder',
    name: 'submitOrder',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/submit-order/submit-order.vue")
  },
  // 订单结算界面
  {
    path: '/submitOrders',
    name: 'submitOrders',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/submit-order/submit-orders.vue")
  },
  // 积分兑换页面
  {
    path: '/integralConversion',
    name: 'integralConversion',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/integral-conversion/integral-conversion.vue")
  },
  // 个人中心收藏界面
  {
    path: '/personal-collect',
    name: 'personal-collect',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/personal-core/personal-collect.vue")

  },
  {
    path: '/personal-index',  // 个人中心 账户中心  公共区域
    name: 'personal-index',
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的*
    },
    component: () => import("../components/personal-core/personal-index.vue")
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})



// 挂载路由导航守卫
// // eslint-disable-next-line no-use-before-define
// router.beforeEach((to, from, next) => {
//   // to 将要访问的路径
//   // from 从什么路径跳转而来
//   // next 是一个函数，放行
//   // next() 放行 next('/login') 强制跳转

//   if (to.path === '/') return next()
//   // 先获取token
//   const tokenStr = localStorage.getItem('token')
//   if (!tokenStr) return next('/')
//   next()
//   //  在判断不是登录而是直接进入的话，会强制将切换的页面返回login
// })


export default router
