<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {
    this.createdfirstStop();
  },
  methods: {
    createdfirstStop() {
      this.$axios.get("https://baseurl.xmw1688.cn/").then(
        (res) => {
          let str = res.data.baseurl + "/api";
          localStorage.setItem("connector", str);
        },
        (err) => {
          this.createdSecondStop()
        }
      );
    },

    // 第二
    createdSecondStop() {
      this.$axios.get("https://baseurl.dwhk918.com/").then(
        (res) => {
          let str = res.data.baseurl + "/api";
          localStorage.setItem("connector", str);
        },
        (err) => {
          this.createdThreeStop()
        }
      );
    },
    // 第三
    createdThreeStop() {
      this.$axios.get("https://baseurl.yuncangxiaodian.com/").then(
        (res) => {
          let str = res.data.baseurl + "/api";
          localStorage.setItem("connector", str);
        },
        (err) => {
          let str = "https://front.xmw1688.com/api";
          localStorage.setItem("connector", str);
        }
      );
    },

    //页面刷新方式
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  background: #f4f4f4;
}

*,
body {
  padding: 0;
  margin: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
