import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'default-passive-events' //  Vue控制台警告：  Added non-passive event listener to a scroll-blocking 'touchmove' event. Consider marking event handler as 'passive' to make the page more responsive
import 'babel-polyfill'//适应ie浏览器
// import './common/font/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element'
import wxlogin from "vue-wxlogin"

Vue.use(element)

import './utils/rem'
// 封装请求
import axios from "axios";

import qs from 'qs';
//下面是将$axios和$qs挂在原型上，以便在实例中能用 this.$axios能够拿到
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.prototype.$http = axios



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
